<template>
  <div>
    <div v-if="correoHighlights">
      <router-link
        v-for="(highlight, index) in correoHighlights"
        :key="index"
        class="link"
        :class="{ yellow: highlight.type == 'hit' }"
        :to="`usuario/${idUsuario}`"
        >{{ highlight.value }}</router-link
      >
    </div>
    <div v-else>
      <router-link class="link" :to="`usuario/${idUsuario}`">{{
        correoUsuario
      }}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    highlights: {
      type: Array,
      default: () => [],
    },
    idUsuario: {
      type: String,
      required: true,
    },
    correoUsuario: {
      type: String,
      required: true,
    },
  },

  computed: {
    correoHighlights() {
      if (this.highlights.length) {
        const hCorreo = this.highlights.find((h) => h.path == "correo");
        return hCorreo ? hCorreo.texts : null;
      }
      return [];
    },
  },
};
</script>
